<!--스타터 첫딜 매칭 관리 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<h6>승인일</h6>
			<div class="flex">
				<div class="flex top-1.5">
					<b-form-radio class="mr-6" v-model="opt.period" name="searchPeriod" value=""
						>전체기간
					</b-form-radio>
					<b-form-radio v-model="opt.period" name="searchPeriod" value="period" />
				</div>
				<datePicker class="w-1/7" model="opt.startDate" />
				<span class="p-2 lh-6">~</span>
				<datePicker class="w-1/7" model="opt.endDate" />
			</div>

			<div class="flex my-2">
				<div class="w-1/7">
					<h6>진행상태</h6>
					<vSelect v-model="opt.step" :clearable="false" :options="stepOpts" />
				</div>
				<div class="w-1/7 mx-4">
					<h6>핫티스트 구분</h6>
					<vSelect v-model="opt.type" :clearable="false" :options="typeOpts" />
				</div>
				<div class="w-1/8 ml-8">
					<h6>키워드 검색</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/3 mx-2">
					<h6>&nbsp;</h6>
					<div>
						<b-form-input
							class=""
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							v-b-tooltip.hover.html
							@keyup.enter="search"
							title="핫티스트 통합검색 대상 필드: 핫트아이디, 이름, 휴대폰번호, 인스타아이디<br>※ 딜 정보, 메모는 제외"
						/>
					</div>
				</div>
				<div>
					<h6 class="block">&nbsp;</h6>
					<b-button class="px-10 top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<b-button class="mb-4 float-right z-10" @click="excelExport">엑셀 다운로드 요청</b-button>
			<tb :inf="inf" :res="res" />
		</b-card>
	</div>
</template>

<script>
const now = new Date(),
	searchKeyOpts = [
		{ label: '핫티스트 통합검색', value: '' },
		{ label: '핫트아이디', value: 'hottId' },
		{ label: '이름', value: 'memberName' },
		{ label: '휴대폰 번호', value: 'phone' },
		{ label: '인스타아이디', value: 'snsLink' },
		{ label: '메모', value: 'comment' },
	],
	defOpt = {
		period: 'period',
		startDate: new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
		endDate: now,

		step: { label: '전체', value: '' },
		type: { label: '전체', value: '' },

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	data() {
		return {
			searchKeyOpts,
			defOpt,
			stepOpts: [],
			typeOpts: [],

			opt: { ...defOpt },
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 2 },

				{ title: '핫티스트 구분', key: 'hottistTypeValue', size: 4 },
				{ title: '핫트아이디', key: 'hottId', size: 6 },
				{ title: '이름', key: 'name', size: 3 },
				{ title: '휴대폰 번호', key: 'phone', size: 4 },
				{ title: '인스타아이디', model: 'insta', key: 'currentInstaId', size: 4 },

				{ title: '최근거절일', key: 'hottDealRejectDt', size: 6, sortable: 1 },
				{ title: '승인일', key: 'hottDealAcceptDt', size: 6, sortable: 1 },

				{ title: '진행상태', key: 'starterStep', size: 6, sortable: 1 },
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 4 },
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				startDate = opt.startDate.get_time('d', 0, ''),
				endDate = opt.endDate.get_time('d', 0, ''),
				starterStep = getDropdownValue(opt.step),
				hottistType = getDropdownValue(opt.type),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					orderBy: getOrder('hottDealAcceptDt'),
					paging: getPaging(),
				},
				//선택 조건에 없는 필수값들 입력
				error = []

			if (opt.period) {
				if (startDate > endDate) error.push('시작일이 종료일보다 큽니다.')
				else data = { ...data, dateType: 'ACCEPT', startDate, endDate }
			}

			if (starterStep) data.starterStep = starterStep
			if (hottistType) data.hottistType = hottistType

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (searchVal) {
				data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
				if (data.searchKey.length > 1) data.searchKey.splice(data.searchKey.length - 1, 1)
				//메모가 들어가면 검색이 이상해진다?
			}
			if (error.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(error)
			else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				//this.selectedItem = {}
				return this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('/starterSelect/firstStarterList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						const tp = v.starterStep
						v.starterStep = v.starterStepValue
						v.starterStepValue = tp
						//정렬 때문에 키 변경하는 게 낫겠다..

						v.memberName = v.name
						v.memberSeq = v.seq
						;['hottDealRejectDt', 'hottDealAcceptDt'].map(k => {
							v[k] = v[k] ? v[k].replace('T', ' ') : ''
						})

						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()

			if (opt.startDate) ex.key('날짜 형식', '승인일')
			ex.date(opt.startDate, opt.endDate)

			if (opt.starterStep) ex.dropdown(opt.starterStep, this.stepOpts, '진행상태')

			if (opt.hottistType) ex.dropdown(opt.hottistType, this.typeOpts, '핫티스트 구분')

			ex.search(opt.searchKey, searchKeyOpts)

			reload(this, false, ex)
		},
		openCalendar(item) {
			this.dealCalendarPopup.open(item)
		},
		openSellResult(item) {
			this.sellResultPopup.open(item)
		},
	},
	created() {
		getConfig('starter_step, hottist_type').then(res => {
			this.stepOpts = res.starter_step.splice(0, 3)
			this.typeOpts = res.hottist_type
			this.search()
		})
		//this.search()
	},
}
</script>
